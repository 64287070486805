import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { Select as SelectAntd } from 'antd';
import cn from 'classnames';
import { Card, BUTTON_TYPES, Button, Input, Checkbox, Select, notification, AtsSkeleton } from '_atoms';
import { SELECT_TYPES } from '_atoms/Select/constants';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import history from 'helpers/history';
import { BUTTON_TEXT } from 'constants/text';
import { ACTIONS, Can, UI } from 'permission';
import { getJobs } from 'actions';
import { setCandidatesJob, clearCandidatesJob, setCandidateExists } from 'store/reducers/jobReducer';
import { getJobLoader } from 'store/selectors/jobSelectors';
import { ReactComponent as Arrow } from 'dist/icons/Vector1.svg';
import img from 'dist/emptyStates/stepsNoCandidates.svg';
import './styles.scss';

export const StepsLeftPanel = ({
  candidates,
  activeStepLabels,
  getCandidatesJobs,
  candidateSearch,
  onlyMyCandidates,
  statuses,
  updateParams,
  setActiveStep,
}) => {
  const { id, step, candidate } = useParams();
  const loader = useSelector(getJobLoader);
  const dispatch = useDispatch();
  const [candidateSelect, setCandidateSelect] = useState();
  const [statusesToUpdate, setStatusesToUpdate] = useState([]);
  const [isRedirected, setIsRedirected] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const candidatesOptions = React.useMemo(() => {
    const options = [];
    statuses.forEach(status => {
      const candidatesByStatus =
        candidates[status.value]?.list.map(c => ({
          value: c.candidate.uuid,
          label: `${c.candidate.first_name_latin} ${c.candidate.last_name_latin}`,
        })) || [];
      options.push({ label: `${status.label} (${candidates[status.value]?.count || 0})`, options: candidatesByStatus });
    });
    return options;
  }, [candidates, statuses]);

  useEffect(() => {
    candidatesOptions.forEach(o =>
      o.options.forEach(option => {
        if (option.value === candidate) {
          setCandidateSelect(option);
        }
      })
    );
  }, [candidatesOptions, candidate]);

  const onOpenFilters = () => setFiltersOpen(o => !o);

  const getNewCandidates = (status, isNew, search = candidateSearch, redirect = true) =>
    getCandidatesJobs(id, step, isNew, {
      limit: 5,
      offset: isNew ? 0 : candidates[status]?.list.length,
      status,
      only_my_candidates: onlyMyCandidates,
      search,
      user_uuid: candidate,
    }).then(resp => {
      const candidateData =
        resp.results.find(item => item.candidate.uuid === candidate)?.candidate ?? resp.results[0]?.candidate;
      if (resp && isNew && redirect && window.location.pathname.includes('show/steps')) {
        history.push(`/jobs/${id}/show/steps/${step}${candidateData?.uuid ? `/${candidateData.uuid}` : ''}`);
      }
      return resp.results;
    });

  const onSelect = value => {
    setStatusesToUpdate(s => [...new Set([...s, value])]);
  };

  const onDeselect = status => {
    setStatusesToUpdate(s => s.filter(el => el !== status));
    const updateRight = !!candidates[status]?.list.find(item => item.candidate.uuid === candidate);
    dispatch(setCandidatesJob({ results: [], count: 0, clear: true, status, next: false }));
    if (!updateRight) return;
    let candidateId = '';
    statuses.forEach(el => {
      if (!candidateId && status !== el.value && candidates[el.value]?.list.length) {
        candidateId = candidates[el.value].list[0].candidate.uuid;
      }
    });
    history.push(`/jobs/${id}/show/steps/${step}${candidateId ? `/${candidateId}` : ''}`);
  };
  const onSearch = s => {
    history.push(`/jobs/${id}/show/steps/${step}`);
    dispatch(clearCandidatesJob());
    Promise.all(statuses.map(status => getNewCandidates(status.value, true, s, false))).then(resp => {
      const results = resp.flat();
      if (results.length) {
        const candidateData = results[0].candidate;
        history.push(`/jobs/${id}/show/steps/${step}${candidateData?.uuid ? `/${candidateData.uuid}` : ''}`);
      }
    });
  };

  const getStatusesOnCloseSelect = () => {
    Promise.all(statusesToUpdate.map(status => getNewCandidates(status, false, candidateSearch, false))).then(resp => {
      const results = resp.flat();
      if (results.length && !candidate) {
        const candidateData =
          results.find(item => item.candidate.uuid === candidate)?.candidate ?? results[0]?.candidate;
        history.push(`/jobs/${id}/show/steps/${step}${candidateData?.uuid ? `/${candidateData.uuid}` : ''}`);
      }
    });
  };

  useEffect(() => {
    if (step) {
      Promise.all(statuses.map(el => getNewCandidates(el.value, true, candidateSearch, false))).then(async resp => {
        const results = resp.flat();
        const candidateId = results.find(item => item.candidate.uuid === candidate)?.candidate.uuid;
        if (candidate && !candidateId && !isRedirected) {
          try {
            const jobs = await dispatch(getJobs(candidate));
            const { active_step, opening } = jobs.find(j => j.uuid === id) || {};
            if (active_step) {
              setActiveStep(active_step.uuid);
              dispatch(setCandidateExists(true));
            } else if (opening && opening.resolution === 'Hired') {
              history.push(`/jobs/${id}/show/hired`);
            } else {
              notification({ message: "The candidate you are looking for isn't assigned to this job." });
            }
            setIsRedirected(true);
          } catch {
            notification({ message: "The candidate you are looking for isn't assigned to this job." });
          }
        } else if (results.length && window.location.pathname.includes('show/steps')) {
          const firstCandidateId = candidateId || results[0]?.candidate.uuid;
          history.push(`/jobs/${id}/show/steps/${step}${firstCandidateId ? `/${firstCandidateId}` : ''}`);
          dispatch(setCandidateExists(true));
        }
      });
    }
  }, [onlyMyCandidates, step]);

  return (
    <Card className="left-panel">
      <div className={cn('left-panel__title', { open: filtersOpen })}>
        <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>Candidates list</Typography.Title2>
        <Button
          icon={<Arrow />}
          type={BUTTON_TYPES.GHOST}
          className={cn('original-svg', { open: filtersOpen })}
          onClick={onOpenFilters}
        />
      </div>
      <div className={cn('left-panel__filters', { open: filtersOpen })}>
        <Input
          className="left-panel__search"
          placeholder="Search by name"
          value={candidateSearch}
          itemProps={{
            onChange: e => updateParams('candidateSearch', e.target.value),
          }}
          maxLength={50}
          onDebounceChange={e => {
            onSearch(e.target.value);
            updateParams('candidateSearch', e.target.value);
          }}
          type="search"
        />
        <Can I={ACTIONS.READ} a={UI.ONLY_MU_CANDIDATES}>
          <Checkbox
            className="left-panel__checkbox"
            label="Only my candidates"
            isSelected={onlyMyCandidates}
            onChange={e => {
              dispatch(clearCandidatesJob());
              history.push(`/jobs/${id}/show/steps/${step}`);
              updateParams('onlyMyCandidates', e.target.checked);
            }}
          />
        </Can>
        <Select
          label="Candidates"
          placeholder="Please select"
          className="left-panel__candidates"
          optionFilterProp="label"
          value={candidateSelect}
          onChange={c => history.push(`/jobs/${id}/show/steps/${step}/${c.value}`)}
          options={candidatesOptions}
          // loading={Object.values(loader).find(l => !!l)}
        />
        <Select
          value={statuses}
          label="Status"
          placeholder="Please select"
          className="left-panel__status"
          optionLabelProp="label"
          onChange={s => {
            const newStatuses = s.map(status => ({ value: status.value, label: status.value }));
            updateParams('statuses', newStatuses);
          }}
          onDropdownVisibleChange={open => (open ? setStatusesToUpdate([]) : getStatusesOnCloseSelect())}
          onDeselect={onDeselect}
          onSelect={onSelect}
          mode={SELECT_TYPES.MULTIPLE}
          allowClear={false}
        >
          {activeStepLabels.map(({ name, candidates_count }) => (
            <SelectAntd.Option key={name} value={name} label={name}>
              {`${name} (${candidates_count})`}
            </SelectAntd.Option>
          ))}
        </Select>
      </div>
      {statuses.length ? (
        activeStepLabels.map(({ name }) => (
          <div className="status-block" key={name}>
            <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD} className="status-block__title">
              {name}
              <span>({candidates[name]?.count || 0})</span>
            </Typography.Title2>
            {candidates[name]?.list.map(item => {
              const en_name = `${item.candidate.first_name_latin} ${item.candidate.last_name_latin}`;
              const ru_name = `${item.candidate.first_name_local} ${item.candidate.last_name_local}`;
              return (
                <NavLink
                  key={item.candidate.uuid}
                  className="left-panel_items"
                  to={`/jobs/${id}/show/steps/${step}/${item.candidate.uuid}`}
                  onClick={() => dispatch(setCandidateExists(true))}
                >
                  <Typography.Text className="left-panel_items__title">
                    {en_name.trim() ? en_name : ru_name}
                  </Typography.Text>
                  {!!ru_name.trim() && !!en_name.trim() && (
                    <Typography.Caption className="left-panel_items__subtitle">{ru_name}</Typography.Caption>
                  )}
                </NavLink>
              );
            })}
            {loader[name] && <AtsSkeleton count={5} height={56} />}
            {candidates[name]?.hasMore && !loader[name] && (
              <Button onClick={() => getNewCandidates(name)} type={BUTTON_TYPES.TEXT} className="status-block__button">
                {BUTTON_TEXT.SEE_MORE}
              </Button>
            )}
          </div>
        ))
      ) : (
        <EmptyState />
      )}
    </Card>
  );
};

const EmptyState = () => (
  <div className="left-panel__empty">
    <img src={img} alt="" />
    <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>No statuses selected</Typography.Title3>
  </div>
);
